<template>
  <v-card outlined width="370" shaped color="#ECEFF1">
    <div class="d-flex flex-no-wrap justify-space-between">
      <v-card-title class="title">{{ product.dish.name }}</v-card-title>
    </div>
    <!-- takes image from first photo -->
    <v-img
      v-if="!isChef && product.dish.photos"
      class="ma-3"
      height="100px"
      :src="photo"
    >
      <!-- product name in front of the picture -->
    </v-img>

    <!-- modifiers -->
    <v-list class="pa-2">
      <!-- order qty -->
      <div v-if="product.orderAmount">
        <small class="font-weight-thin">Amount:&#9;</small>
        <mark class="font-weight-bold font-italic">{{
          product.orderAmount
        }}</mark>
      </div>
      <div v-if="product.modifiers">
        <small class="font-weight-thin">Modifiers:</small>
        <view-modifiers :modifiers="product.modifiers" />
      </div>
      <!-- special instructions -->
      <div v-if="product.specialInstruction">
        <small class="font-weight-bold">Special Instruction:</small>
        <div>
          <span class="font-weight-thin font-italic">{{
            product.specialInstruction
          }}</span>
        </div>
      </div>
    </v-list>
  </v-card>
</template>

<script>
import ViewModifiers from "../dish/ViewModifiers";
export default {
  props: {
    isChef: {
      type: Boolean
    },
    product: {
      type: Object
    }
  },
  components: { ViewModifiers },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
  computed: {
    photo(){
      return this.product.dish.photos && this.product.dish.photos.length>0? this.product.dish.photos[0].url  || this.product.dish.photos[0].imageUrl:null
    }
  },

};
</script>
